@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

/* 
font-family: 'Noto Sans TC', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Roboto', sans-serif; 
*/
/*Debug*/
/* * {
    box-sizing: border-box !important;
    outline: 1px solid limegreen !important;
} */

/* html {
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1440 - 300)));
} */
html {
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
ol {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 2px;
    line-height: 1.6;
    font-weight: bolder;
}

p,
a {
    font-family: 'Noto Sans TC', sans-serif;
    line-height: 1.6;
    letter-spacing: 1px;
    font-weight: bold;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.debug-sticky {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 0
}

.my-customer-cartBody {
    width: 200px;
    height: 200px;
    clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
    background: linear-gradient(0deg, purple, orange);
    display: block;
}

.my-customer-cartHandle {
    content: '';
    width: 100px;
    height: 100px;
    display: block;
    border-radius: 50%/ 60% 60% 40% 40%;
    box-shadow: 0 0 0 10px black;
    margin-bottom: -50px;
}